import { AppSettings } from "booking_app/values/app-settings";
import { DocumentService } from "booking_app/services/document.service";
import { FlightSortType, SortType } from "booking_app/types/sort-type";
import { PaymentMethod } from "booking_app/types";
import { SearchSortingService } from "booking_app/services/search-sorting.service";
import { GlobalStateService } from "booking_app/services/global-state.service";
import { OldhamAdobeAnalyticsService } from "../services/adobe-analytics.service";
import { LoginModalSizeOption, LoginModalWindowClassOption } from "booking_app/types/login-modal-config";
import { metaData } from "../constants/meta-data";
import "../constants/modal-settings";
import { adobeAnalyticsData } from "../constants/adobe-analytics-data";

import {
  ProductType,
  Tenant,
  TravelType,
} from "booking_app/types";

angular.module("BookingApp").config([
  "$routeProvider", "$injector",
  ($routeProvider, $injector) => {
    $routeProvider
      .when("/:travelType", {
        controller: "SearchCtrl",
        templateUrl: "/html/whitelabel/oldham/search",
        resolve: {
          parseUrl: $injector.get("ParseTrafficParams"),
        },
      }).when("/company/faqs", {
        redirectTo: "/company/ocbc-faqs",
      }).when("/company/ocbc-faqs", {
        controller: "PageCtrl",
        templateUrl: "/html/company/faqs2",
      }).when("/terms/terms_condition", {
        redirectTo: "/terms/ocbc-terms-conditions",
      }).when("/terms/ocbc-terms-conditions", {
        controller: "PageCtrl",
        templateUrl: "/html/terms/terms_condition2",
      }).when("/terms/privacy", {
        redirectTo: "/terms/ocbc-privacy-policy",
      }).when("/terms/ocbc-privacy-policy", {
        controller: "PageCtrl",
        templateUrl: "/html/terms/privacy_policy2",
      });
  },
]);

class OldhamController {

  static $inject = [
    "$rootScope",
    "$location",
    "AppConfig",
    "AppSettings",
    "DocumentService",
    "SearchSortingService",
    "GlobalStateService",
    "OldhamAdobeAnalyticsService",
  ];

  constructor(
    private $rootScope: any,
    private $location: any,
    private appConfig: any,
    private appSettings: AppSettings,
    private documentService: DocumentService,
    private searchSortingService: SearchSortingService,
    private globalStateService: GlobalStateService,
    private oldhamAdobeAnalyticsService: OldhamAdobeAnalyticsService,
  ) {
    // to make sure it uses new details page
    this.appSettings.enableChildrenSearch = true;
    this.appSettings.showPerNightText = true;
    this.appSettings.carsListBtnLabel = "Select";
    this.appSettings.supportedProductTypes = [ProductType.REDEEM];
    this.appSettings.mapboxHotelMarkerColor = "#009E9B";
    this.appSettings.tenant = Tenant.OLDHAM;

    let cancellationFeeTextCheckout = "wl.ocbc.cancellation_policy_service_fee_long";
    if (this.$rootScope.isUserLoggedIn()) {
      const channel = this.$rootScope.userDetails.user.sign_in_origin;
      const enableMBS = (channel !== "PAO" && !!this.appConfig?.feature_flags?.oldham_digital);
      if (channel === "PAO") {
        cancellationFeeTextCheckout = "wl.ocbc.pay_anyone_cancellation_policy_service_fee_long";
      } else if (channel === "MBS" || channel === "TP" || enableMBS) {
        cancellationFeeTextCheckout = "wl.ocbc.digital_cancellation_policy_service_fee_long";
      }
    }
    this.appSettings.cancellationFeeText = {
      details: "wl.ocbc.free_cancellation_except_service_fee",
      checkout: cancellationFeeTextCheckout,
      manageBookings: "wl.ocbc.cancellation_policy_service_fee_short",
      success: "wl.ocbc.cancellation_policy_service_fee_short",
    };

    this.$rootScope.globalState.specialRequestText = "wl.ocbc.special_request_text";
    this.$rootScope.globalState.sessionTimerText = "wl.ocbc.session_timer_text";
    this.$rootScope.globalState.showTotalNights = true;
    this.$rootScope.globalState.hideUseAddressFromProfile = true;
    this.$rootScope.globalState.serviceEmail = "bookings@travelwithocbc.com";
    this.$rootScope.selectedCurrency = this.$rootScope.currenciesService.findByCode("SGD");
    this.$rootScope.convert_rate = this.$rootScope.selectedCurrency.rate;
    this.appSettings.hasCouponCode = true;
    this.appSettings.reloadOnSearch = true;
    this.appSettings.reloadOnQuickSearch = true;
    this.appSettings.contactUsConfig.contactUsParagraphText = "wl.ocbc.contactus_text";
    this.appSettings.carsBookingPage.confirmedIcon = "/oldham/icons/icn-success.png";
    this.appSettings.carsBookingPage.pendingIcon = "/oldham/icons/icn-pending.png";
    this.appSettings.carsBookingPage.pendingEmailInText = "wl.ocbc.purchase.pending.email_in_24h";
    this.appSettings.isTravelBreakdownFlexDirectionDefault = false;

    this.appSettings.roomGuestParameters = {
      labelOnlyRoomCounter: true,
      hasRoomGuestCloser: true,
      labelOnlySummaryText: true,
    };
    this.appSettings.destinationFieldPlaceHolder = "";
    this.appSettings.pointsCashSliderSettings.hasCustomSliderLabel = true;
    this.appSettings.showCurrencyInFilterSliders = false;
    // Ocbc can go upto 100% cash. So the ceilingLabelPrefix is set to empty
    this.appSettings.pointsCashSliderSettings.ceilingLabelPrefix = "";
    this.appSettings.pointsCashSliderSettings.alwaysShowPointsSlider = true;
    this.appSettings.pointsCashSliderSettings.pointSliderDefaultValue = 20;
    this.appSettings.pointsCashSliderSettings.incrementPointsSliderCounter = false;
    this.appSettings.customFlightIcon = true;
    this.appSettings.showFlightSelectionState = true;
    this.appSettings.flightSegmentArrivalDisplay = true;
    this.appSettings.defaultAirlineSearchCode = "SIN";
    this.appSettings.showCancelPolicyOnRedeem = true;
    this.appSettings.payWithPoints.roundToNearest = 1;
    this.appSettings.checkoutFormScrollOffset = 100;

    this.appSettings.sessionCounter = {
      isEnabled: true,
      sessionTime: 13 * 60 * 1000,
      countdownTime: 2 * 60 * 1000,
      pollingInterval: 1 * 60 * 1000,
    };

    this.appSettings.landingPageUrl = { redeem: "wl-oldham" };

    this.appSettings.storeCreditCard = true;
    this.appSettings.hasMultiplePaymentMethods = true;
    this.appSettings.logoutUrl = "/whitelabel/oldham/logout";
    this.appSettings.canSelectDaysOfPreviousAndNextMonth = true;
    this.appSettings.sortSettings.canUseFullCashOnRedemption = true;
    this.appSettings.sortSettings.canUseFullPointsOnRedemption = true;
    this.appSettings.flightsEnabledSortingOptions.push(...[FlightSortType.MILES_LOHI, FlightSortType.MILES_HILO]);
    this.appSettings.loginModalConfig.loginModalWindowClass = LoginModalWindowClassOption.LOGIN_MODAL_VALIDATION_CLASS;
    this.appSettings.loginModalConfig.loginModalSize = LoginModalSizeOption.MEDIUM_SIZE;
    this.appSettings.requireLoginOnConfirmationPage = true;
    this.appSettings.mobileResultPopupConfig = {
      filterIconSource: "oldham/icons/filter-icon.png",
      sortIconSource: "oldham/icons/sort-icon.png",
      mapIconSource: "oldham/icons/map-icon.png",
    };
    this.appSettings.defaultPaymentMethod = PaymentMethod.PAY_ANYONE;
    this.appSettings.checkoutSettings.insufficientPointsModalCheck = true;
    this.appSettings.multiplePointsPartner = true;
    this.appSettings.defaultCountryInCheckout = {
      hasDefaultCountry: true,
      defaultCountryCode: "SG",
    };

    this.appSettings.showBetterRatesOnly = true;
    this.appSettings.ccValidationUseCardErrorModal = true;
    this.appSettings.customFlightsDateTimeLabel = "ddd, DD MMM YYYY";
    this.appSettings.stripePaymentIntentsEnabled = true;
    this.appSettings.mastheadBannerSettings.landing_page_banner.hotels = "ocbc-april-promo-2";
    this.appSettings.mastheadBannerSettings.landing_page_banner.cars = "ocbc-april-promo-2";
    this.appSettings.mastheadBannerSettings.landing_page_banner.flights = "ocbc-april-promo-2";
    // RootScope Settings
    this.$rootScope.alwaysShowPointsSlider = this.appSettings.pointsCashSliderSettings.alwaysShowPointsSlider;
    this.appSettings.pointsCashSliderSettings.sliderRightLabel = "100% Cash";
    this.appSettings.pointsCashSliderSettings.sliderLeftLabel = "0% Cash";
    this.$rootScope.globalState.productType = this.$rootScope.initializeProductType(ProductType.REDEEM);
    this.$rootScope.globalState.requireLoginOnCheckout = true;
    this.$rootScope.globalState.carsRedemptionLabel = "Redemption Range";
    this.$rootScope.globalState.customLoaderFile = "/html/whitelabel/oldham/custom_loader";
    this.appSettings.prismicSettings.enabled = true;
    this.appSettings.prismicSettings.faqs = "te-ocbc--faq";
    this.appSettings.prismicSettings.terms = "te-ocbc--terms";
    this.appSettings.prismicSettings.privacyPolicy = "te-ocbc--privacy-policy";
    this.appSettings.mastheadBannerSettings.couponCodeText = "Save more by offsetting your booking cost with your OCBC rewards points.";
    this.appSettings.mastheadBannerSettings.headline = "wl.oldham.masthead.headline";
    this.appSettings.mastheadBannerSettings.subheadline = "wl.oldham.masthead.promo.subheadline";
    this.appSettings.mastheadBannerSettings.tncheadline = false;
    this.appSettings.useTravelfusionConfirmationPage = true;

    if (this.globalStateService.isTravelType(TravelType.HOTELS)) {
      const hotelsEnabledSortingOptions: SortType[] = [SortType.DEAL, SortType.BEST_RATED, SortType.PAY_POINTS_LOHI,
      SortType.PAY_POINTS_HILO, SortType.DISTANCE_LOHI, SortType.PAY_POINTS_CASH_LOHI,
      SortType.PAY_POINTS_CASH_HILO, SortType.RATING_LOHI, SortType.RATING_HILO];
      this.searchSortingService.initializePermittedSortingOptions(hotelsEnabledSortingOptions);
      this.searchSortingService.setCurrent(SortType.RATING_HILO);
    } else if (this.globalStateService.isTravelType(TravelType.CARS)) {
      const carsEnabledSortingOptions: SortType[] = [SortType.PAY_POINTS_LOHI,
      SortType.PAY_POINTS_HILO, SortType.PAY_POINTS_CASH_LOHI, SortType.PAY_POINTS_CASH_HILO];
      this.searchSortingService.initializeCarsPermittedSortingOptions(carsEnabledSortingOptions);
    }

    this.documentService.initDocumentTitleAndMeta(metaData);
    this.oldhamAdobeAnalyticsService.adobeAnalyticsData = adobeAnalyticsData;
    this.oldhamAdobeAnalyticsService.initiateAnalyticsOnRouteChange();
  }
}

angular.module("BookingApp").controller("OldhamController", OldhamController);
